import { airportsMap } from '../../utilities/airports'
import { encodeStringToUrl } from '../../utilities/string'
import { BASE_URL } from './config'

export const onSendFormRequest = (formData, history) => {
    const from = encodeStringToUrl(formData.from)
    const to = encodeStringToUrl(formData.to)
    const jetsList = formData.jets?.map((jet) => jet.id).join(',')
    const link = `/charter-inquiry?from=${from}&to=${to}&jets=${jetsList}`

    if (history) {
        history.push(link)
        return
    }
    window.open(link, '_self')
}

export const filterAirportsApi = async (id = 'from', form) => {
    if (id !== 'from' && id !== 'to') {
        return []
    }
    const airportSearch = form[id]
    try {
        const response = await fetch(`${BASE_URL}airports/search?search=${airportSearch}`)
        const { airports } = await response.json()

        const filteredAirports = form.filteredAirports || {}
        filteredAirports[id] = airportsMap(airports)
        return filteredAirports
    } catch (error) {
        return []
    }
}
