import React, { Component } from 'react'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import ReactGA from 'react-ga'
import AppRouter from './navigation/AppRouter'
import rootReducer from './reducers/rootReducer'
import middlewares from './services/api/middlewares'

class App extends Component {
    constructor(props) {
        super(props)
        const enhancer = composeWithDevTools(applyMiddleware(thunk, ...middlewares))
        this.store = createStore(rootReducer, enhancer)
        ReactGA.initialize('UA-215911057-1')
        ReactGA.pageview(window.location.pathname + window.location.search)
    }

    /**
     * Custom event
     * ReactGA.event({
     *   category: 'User',
     *   action: 'Sent message'
     * });
     */
    render() {
        return (
            <HelmetProvider>
                <Helmet>
                    <title>{'Welcome! - JustFlyEmpty'}</title>
                    <link rel={'canonical'} href={'https://justflyempty.com/'} />
                </Helmet>
                <Provider store={this.store}>
                    <div style={{ overflow: 'auto' }}>
                        <ToastContainer />
                        <AppRouter />
                    </div>
                </Provider>
            </HelmetProvider>
        )
    }
}

export default App
