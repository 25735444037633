export const isEmailValid = (email) => {
    const reg = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
    return !!(email && reg.test(email))
}

export const isFlightValid = (data, validationKeys = []) => {
    let isValid = true
    const errors = []

    for (let i = 0; i < validationKeys.length; i += 1) {
        const key = validationKeys[i]
        switch (key) {
            case 'email':
                if (!(data[key] && isEmailValid(data[key]))) {
                    errors.push(key)
                }
                break

            case 'name':
                if (!(data?.name && data?.name?.length > 2)) {
                    errors.push(key)
                }
                break

            case 'returnDate':
                if (data.charterType !== 'one-way') {
                    if (!data[key]) {
                        isValid = false
                        errors.push(key)
                        break
                    }
                }
                break
            default:
                if (!data[key] || data[key]?.length < 3) {
                    isValid = false
                    errors.push(key)
                    break
                }
                break
        }
    }

    if (data.from?.length > 0 && data.from === data.to) {
        errors.push('from')
        errors.push('to')
        isValid = false
    }

    return { isValid, errors }
}

export const isFlightRequestValid = (data, validationKeys = []) => {
    let isValid = true
    const errors = []

    for (let i = 0; i < validationKeys.length; i += 1) {
        const key = validationKeys[i]
        switch (key) {
            case 'email':
                if (!(data[key] && isEmailValid(data[key]))) {
                    errors.push(key)
                }
                break

            case 'name':
                if (!(data?.name && data?.name?.length > 2)) {
                    errors.push(key)
                }
                break
            case 'pax':
                if (!data?.pax > 0) {
                    errors.push(key)
                }
                break
            case 'emptyLegID':
                if (!data?.emptyLegID > 0) {
                    errors.push(key)
                }
                break

            default:
                if (!data[key] || data[key]?.length < 3) {
                    isValid = false
                    errors.push(key)
                    break
                }
                break
        }
    }
    return { isValid, errors }
}
//
// export const isPersonValid = (data) => {
//     const errors = []
//     let isValid = true
//     const validationKeys = ['email', 'name', 'phone']
//
//     for (let i = 0; i < validationKeys.length; i += 1) {
//         const key = validationKeys[i]
//         switch (key) {
//             case 'email':
//                 if (!isEmailValid( data[key] )) {
//                     errors.push( key )
//                 }
//
//                 break
//             default:
//                 if (!data[key]) {
//                     isValid = false
//                     errors.push( key )
//                     break
//                 }
//                 break
//         }
//     }
//
//     return { isValid, errors }
// }

export const isChangeRouteValid = (validationKeys = [], data = []) => {
    const errors = []
    let isValid = true

    for (let i = 0; i < validationKeys.length; i += 1) {
        const key = validationKeys[i]
        switch (key) {
            case 'pax':
                if (data[key] < 0) {
                    errors.push(key)
                }
                break
            case 'email':
                if (!isEmailValid(data[key])) {
                    errors.push(key)
                }
                break
            case 'from':
            case 'to':
                if (!data[key] || data[key].length < 3 || data.from === data.to) {
                    isValid = false
                    errors.push(key)
                    break
                }
                break
            default:
                if (!data[key] || data[key].length < 3) {
                    isValid = false
                    errors.push(key)
                    break
                }
                break
        }
    }

    return { isValid, errors }
}
