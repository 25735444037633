import React from 'react'
import { Link } from 'react-router-dom'
import { LINK_URL } from '../../../services/api/config'

const MobileMenu = ({ setPopupMenuVisible = () => {}, isPopupMenuVisible }) => {
    const scrollAndMenuHidden = () => {
        setPopupMenuVisible(false)
        window.scrollTo({ top: 700, behavior: 'smooth' })
    }

    return (
        <div
            className={
                isPopupMenuVisible ? 'mobile-menu-container active' : 'mobile-menu-container'
            }>
            <div className={'mobile-menu'}>
                <a href={LINK_URL} onClick={() => setPopupMenuVisible(false)}>
                    {'Home'}
                </a>
                <Link to={'/daily-offers'} onClick={() => setPopupMenuVisible(false)}>
                    {'Daily offers'}
                </Link>
                <a href={LINK_URL} onClick={() => scrollAndMenuHidden()}>
                    {'Book A Jet charter'}
                </a>
                <a href={`${LINK_URL}subscribe`} onClick={() => setPopupMenuVisible(false)}>
                    {'Subscribe For Offers'}
                </a>
                <a href={`${LINK_URL}membership`} onClick={() => setPopupMenuVisible(false)}>
                    {'Membership'}
                </a>
                <a href={`${LINK_URL}signup/agent`} onClick={() => setPopupMenuVisible(false)}>
                    {'Become Our Business Partner'}
                </a>
                <a href={`${LINK_URL}about`} onClick={() => setPopupMenuVisible(false)}>
                    {'About Us'}
                </a>
                <a href={`${LINK_URL}our-standards`} onClick={() => setPopupMenuVisible(false)}>
                    {'Our Standards'}
                </a>
            </div>
        </div>
    )
}

export default MobileMenu
