import React, { lazy, Suspense } from 'react'
import { Helmet } from 'react-helmet-async'

const AboutSafety = lazy(() => import('../../organisms/AboutSafety/AboutSafety'))
const BecomePartner = lazy(() => import('../../organisms/BecomePartner/BecomePartner'))
const BookCharter = lazy(() => import('../../organisms/BookCharter/BookCharter'))
const Discover = lazy(() => import('../../organisms/Discover/Discover'))
const Safety = lazy(() => import('../../organisms/Safety/Safety'))
const HeroSection = lazy(() => import('../../molecules/HeroSection/HeroSection'))

function HomeTemplate(props) {
    const { onClick, params, history } = props
    return (
        <div>
            <Helmet>
                <meta charSet={'UTF-8'} />
                <title>{'Welcome to JustFlyEmpty! Jet-Set Deals Tailored Just for You'}</title>
                <meta name={'description'} content={''} />
                <meta name={'keywords'} content={'empty legs, private charter'} />
            </Helmet>
            <Suspense fallback={<div>{'Loading... '}</div>}>
                <Discover
                    title={'High Flying at Lower Prices'}
                    body={
                        'Discover Empty Legs: The most convenient and cost effective way to book jet charter flights. All the perks of a private jet – without the price tag.'
                    }
                    onClick={onClick}
                />
            </Suspense>
            <Suspense fallback={<div>{'Loading... '}</div>}>
                <HeroSection
                    title={'FLY. EXPLORE. UNLOCK THE WORLD. ADVENTURE AWAITS.'}
                    subtitle={'Discover the best tailored way to fly.'}
                    backImage={'business-hero.jpg'}
                />
            </Suspense>
            <Suspense fallback={<div>{'Loading... '}</div>}>
                <BookCharter params={params} />
            </Suspense>
            <Suspense fallback={<div>{'Loading... '}</div>}>
                <Safety />
            </Suspense>
            <Suspense fallback={<div>{'Loading... '}</div>}>
                <BecomePartner />
            </Suspense>
            <Suspense fallback={<div>{'Loading... '}</div>}>
                <AboutSafety history={history} />
            </Suspense>
        </div>
    )
}

export default HomeTemplate
