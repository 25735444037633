import React, { useEffect } from 'react'
import OurStandardsTemplate from '../../components/templates/ourStandards/OurStandardsTemplate'
// import OurStandards

const StandardsScene = () => {
    useEffect(() => {
        document.title = 'Our standards'
    })

    return <OurStandardsTemplate />
}

export default StandardsScene
