const envURL = {
    prod: 'https://justflyempty.com/api/',
    dev: 'http://127.0.0.1:8000/api/'
}

const shouldUseLocal = true

export const BASE_URL =
    (window.location.host === 'localhost:3000' || window.location.host === 'localhost:8000') &&
    shouldUseLocal
        ? envURL.dev
        : envURL.prod

export const LINK_URL =
    window.location.host === 'localhost:3000'
        ? `http://${window.location.host}/`
        : `https://${window.location.host}/`

export const BROCHURE_URL = `/JustFlyEmptyBrochure.pdf`
