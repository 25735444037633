import React from 'react'
import { withRouter } from 'react-router-dom'
import DailyOffersTemplate from '../../components/templates/dailyOffers/DailyOffersTemplate'
import { loadDailyOffers, onSendEmptyLegInquiry } from '../../services/api/emptyLegs'
import { isFlightRequestValid } from '../../utilities/flight'

// import { validateForm } from '../../utilities/form'

class DailyOffersScene extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            formData: {},
            responseData: {},
            dailyOffers: [],
            activeLeg: null,
            errors: [],
            isStoredSuccessfully: null
        }
    }

    async componentDidMount() {
        this.loadOffers().then()
    }

    loadOffers = async (page = 1) => {
        const { data, responseData } = await loadDailyOffers(page)
        this.setState({ dailyOffers: data, activeLeg: null, responseData })
    }

    onUpdateForm = async (key, value) => {
        const { formData } = this.state
        const data = { ...formData }

        // const requiredFields = ['email', 'phone', 'name', 'pax']

        // if (value?.length > 2 && arrayContains(requiredFields, key)) {
        //     data.filteredAirports = await filterAirportsApi(key, formData)
        // }
        data.errors = [...(data.errors || []).filter((error) => key !== error)]
        data[key] = value
        this.setState({ formData: data })
    }

    onSubmitForm = async () => {
        const { formData, activeLeg } = this.state
        formData.emptyLegID = activeLeg?.leg?.id
        const { isValid, errors } = isFlightRequestValid(formData, [
            'email',
            'phone',
            'name',
            'emptyLegID'
        ])

        if (errors.length > 0) {
            this.setState({ errors })
            return
        }
        this.setState({ formData: { ...formData }, errors: [...errors] })
        if (isValid) {
            const { history } = this.props
            onSendEmptyLegInquiry(
                { ...formData, type: activeLeg.type, pax: activeLeg.pax || 1 },
                history
            ).then((r) => {
                // console.log('response', r)
                if (r.status === true) {
                    this.setState({ errors: [], activeLeg: null, isStoredSuccessfully: true })
                    return
                }
                this.setState({ errors: r.errors, isStoredSuccessfully: false })
            })
        }
    }

    onPress = (action, leg) => {
        let activeLeg = { type: action, leg }

        switch (action) {
            case 'cancelled':
                activeLeg = null
                this.setState({ formData: { shouldValidate: false }, isStoredSuccessfully: null })
                break

            case 'ok':
                activeLeg = null
                this.setState({ formData: { shouldValidate: false }, isStoredSuccessfully: null })
                break

            case 'confirmed':
                return this.onSubmitForm(activeLeg)

            case 'try-again':
                return this.setState({ isStoredSuccessfully: null })
            default:
                break
        }

        this.setState({
            activeLeg
        })
        return null
    }

    onChangePage = async (page) => {
        this.loadOffers(page)
    }

    render() {
        const { history } = this.props
        const { formData, errors, dailyOffers, activeLeg, isStoredSuccessfully, responseData } =
            this.state

        return (
            <DailyOffersTemplate
                formData={formData}
                onUpdateForm={this.onUpdateForm}
                onSubmitForm={this.onSubmitForm}
                history={history}
                dailyOffers={dailyOffers}
                errors={errors}
                onPress={this.onPress}
                activeLeg={activeLeg}
                isStoredSuccessfully={isStoredSuccessfully}
                responseData={responseData}
                onChangePage={this.onChangePage}
            />
        )
    }
}

export default withRouter(DailyOffersScene)
