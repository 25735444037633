import React from 'react'
import { arrayContains } from '../../../utilities/array'
import InputField from '../../atoms/InputField/InputField'
import JetsPicker from './JetsPicker'

const FlightForm = ({ formData, onUpdateForm, useJets = true, shouldShowErrors = false }) => {
    const jets = {
        TURBO_PROP: { name: 'jetType', id: 'TURBO_PROP', label: 'Turbo Prob' },
        LIGHT_JET: { name: 'jetType', id: 'LIGHT_JET', label: 'Light Jet' },
        SUPER_LIGHT: { name: 'jetType', id: 'SUPER_LIGHT', label: 'Super light' },
        MID_SIZE: { name: 'jetType', id: 'MID_SIZE', label: 'Mid Size' },
        SUPER_MID_SIZE: { name: 'jetType', id: 'SUPER_MID_SIZE', label: 'Super mid' },
        HEAVY_JET: { name: 'jetType', id: 'HEAVY_JET', label: 'Heavy Jet' }
    }

    return (
        <div className={'form-container'}>
            <div className={'form-section-title destination-title'}>{'Destination'}</div>
            {shouldShowErrors && formData?.errors?.length > 0 && (
                <p className={'error-info'}>
                    {'Please complete the missing fields before sending.'}
                </p>
            )}
            <div className={'select-container'}>
                <InputField
                    placeholder={'Where from?'}
                    id={'from'}
                    defaultValue={formData?.from}
                    isInvalid={arrayContains(formData?.errors, 'from')}
                    className={`airport-picker`}
                    isIndented
                    options={formData?.filteredAirports?.from || []}
                    inputType={'select'}
                    onChange={onUpdateForm}
                />
                <InputField
                    placeholder={'Where to?'}
                    id={'to'}
                    isInvalid={arrayContains(formData?.errors, 'to')}
                    defaultValue={formData?.to}
                    className={`airport-picker`}
                    isIndented
                    options={formData?.filteredAirports?.to || []}
                    inputType={'select'}
                    onChange={onUpdateForm}
                />
                <div className={'from-to-line'}>
                    <img src={'/images/connection.svg'} alt={'route'} />
                </div>
            </div>
            {useJets && (
                <div>
                    <JetsPicker jets={jets} onChange={onUpdateForm} selected={formData.jets} />
                </div>
            )}
        </div>
    )
}
export default FlightForm
