import React from 'react'
import PrimaryButton from '../../atoms/PrimaryButton/PrimaryButton'
// import { statusTypes } from '../../templates/charter/selectors'

const FormFooter = ({
    primaryActionTitle,
    cancelActionTitle,
    onClick,
    section = '',
    onCancel,
    status
}) => (
    <div className={'form-footer'}>
        <div className={'form-row'}>
            <PrimaryButton
                title={primaryActionTitle}
                paddingType={'medium'}
                // disabled={status === statusTypes.SENDING}
                onClick={(e) => {
                    if (onClick) {
                        e.preventDefault()
                        onClick(section === 'flight' ? 'person' : 'flight')
                    }
                }}
            />
            {cancelActionTitle && (
                <PrimaryButton
                    title={cancelActionTitle || 'Cancel'}
                    type={'cancel'}
                    paddingType={'medium'}
                    disabled={status === 'sending' ? 'disabled' : ''}
                    onClick={(e) => {
                        e.preventDefault()
                        onCancel('flight')
                    }}
                />
            )}
        </div>
    </div>
)

export default FormFooter
