import React, { Component } from 'react'
import MembershipTemplate from '../../components/templates/membership/MembershipTemplate'
import { isEmailValid } from '../../utilities/flight'
import { toJson } from '../../utilities/string'
import { BASE_URL } from '../../services/api/config'
import { statusTypes } from '../../components/templates/charter/selectors'

class MembershipScene extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            url: null,
            formData: { name: '', email: '' }
        }
    }

    componentDidMount() {
        document.title = 'Membership - JustFlyEmpty'
    }

    onUpdateForm = (id, value) => {
        const { formData } = this.state
        switch (id) {
            case 'name':
                formData.name = value
                break
            case 'email':
                formData.email = value
                break
            default:
                return
        }
        formData[id] = value
        this.setState({
            formData: {
                ...formData
            }
        })
    }

    onSubmit = async () => {
        const { formData } = this.state ?? {}
        if (formData?.name.length < 3) {
            this.setState({
                error: 'Invalid name'
            })
            return
        }

        if (!isEmailValid(formData?.email)) {
            this.setState({
                error: 'Invalid email address'
            })
        }

        const request = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': true
            },
            body: await toJson({
                ...formData
            })
        }
        try {
            const response = await fetch(`${BASE_URL}membership/store-info`, request)
            if (response.status === 200) {
                const responseData = await response.json()
                const { status } = responseData
                this.setState({ url: status, error: null })
            }
        } catch (error) {
            this.setState({ ...formData, error: statusTypes.SENDING_ERROR })
        }
    }

    render() {
        const { formData, error, url } = this.state
        return (
            <MembershipTemplate
                formData={formData}
                onUpdateForm={this.onUpdateForm}
                onSubmit={this.onSubmit}
                error={error}
                url={url}
            />
        )
    }
}

export default MembershipScene
