import React from 'react'
import CharterInquiryTemplate from '../../components/templates/charter/CharterInquiryTemplate'

const CharterInquiryScene = () => {
    const params = () => {
        const urlSearchParams = new URLSearchParams(window.location.search)
        return Object.fromEntries(urlSearchParams.entries())
    }
    return <CharterInquiryTemplate params={params()} />
}

export default CharterInquiryScene
