import React from 'react'
import { arrayContains } from '../../../../utilities/array'
import { formattedDate } from '../../../../utilities/date'
import InputField from '../../../atoms/InputField/InputField'
import TextTitle from '../../../atoms/TextTitle/TextTitle'

const RequestEmptyLegForm = ({ activeLeg, onSubmit, onUpdateForm, errors, formData }) => {
    const { leg } = activeLeg
    const submitFormData = (data) => {
        onSubmit('confirmed', { ...data, type: 'request-quote' })
    }

    return (
        <>
            <div className={'header'}>
                <TextTitle
                    headingLevel={2}
                    title={`Request Quote`}
                    className={'no-margin-bottom'}
                />
                <TextTitle
                    headingLevel={4}
                    title={`${leg?.from} - ${leg?.to}, ${formattedDate(leg?.date)}`}
                />
            </div>
            <form
                onSubmit={submitFormData}
                method={'post'}
                style={{ width: '80%', marginLeft: '10%' }}>
                <p style={{ textAlign: 'left', margin: '1rem 0' }}>
                    {
                        'Please, fill up the form and empty leg details and price will be sent to your inbox.'
                    }
                </p>
                <InputField
                    label={'Name'}
                    id={'name'}
                    isInvalid={arrayContains(errors, 'name')}
                    placeholder={'Enter full name'}
                    className={'label-left'}
                    onChange={onUpdateForm}
                    defaultValue={formData?.name}
                />
                <InputField
                    className={'label-left'}
                    label={'Email'}
                    placeholder={'Enter email address'}
                    onChange={onUpdateForm}
                    defaultValue={formData?.email}
                    id={'email'}
                    isInvalid={arrayContains(errors, 'email')}
                />
                <InputField
                    className={'label-left'}
                    label={'Phone'}
                    placeholder={'Enter phone number'}
                    onChange={onUpdateForm}
                    id={'phone'}
                    defaultValue={formData?.phone}
                    isInvalid={arrayContains(errors, 'phone')}
                />
                <InputField
                    className={'label-left'}
                    label={'PAX'}
                    id={'pax'}
                    defaultValue={formData?.pax || 1}
                    inputType={'number'}
                    onChange={onUpdateForm}
                    isInvalid={arrayContains(errors, 'pax')}
                />
                <p
                    style={{
                        textAlign: 'left',
                        marginTop: 10,
                        marginBottom: 10,
                        lineHeight: '110%'
                    }}>
                    {'Let us know which details of selected empty leg you would like to change? '}
                </p>
                <InputField
                    className={'label-left'}
                    label={'Message'}
                    id={'message'}
                    defaultValue={formData?.message}
                    placeholder={'I would prefer time of the flight to be...'}
                    onChange={onUpdateForm}
                    inputType={'textarea'}
                />
            </form>
        </>
    )
}

export default RequestEmptyLegForm
