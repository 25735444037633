export const arrayContains = (array = [], item, id = null) => {
    if (id) {
        return array.filter((arrayItem) => arrayItem[id] === item[id]).length > 0
    }

    if (typeof array === 'object') {
        return array.indexOf(item) !== -1
    }
    return array.indexOf(item) !== -1
}

export const test = () => {}
