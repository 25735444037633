import React from 'react'
import { Helmet } from 'react-helmet-async'
import HeaderTitle from '../../atoms/HeaderTitle/HeaderTitle'
import MotoText from '../../atoms/MotoText/MotoText'
import TextTitle from '../../atoms/TextTitle/TextTitle'
import MembershipBrochureForm from '../../molecules/membershipForm/membershipBrochureForm'

const MembershipTemplate = ({ formData, onUpdateForm, onSubmit, error, url }) => {
    return (
        <>
            <HeaderTitle title={'Membership'} />
            <Helmet>
                <title>{'Membership - JustFlyEmpty'}</title>
            </Helmet>
            <div className={'container membership-container'}>
                <MotoText
                    title={'ANY JET. ANY TIME. ANYWHERE.'}
                    titleTop={7}
                    marginBottom={20}
                    marginTop={20}
                />
                <div className={'choose-container'}>
                    <div className={'text-container'}>
                        <TextTitle headingLevel={2} title={'Our membership benefits'} />

                        <p className={'text'}>
                            {
                                'If you are looking for a custom flight membership that is designed to better suit your needs and make your life easier, then look no further!'
                            }
                        </p>
                        <p className={'text'}>
                            {
                                'We offers annual subscription-based flight membership customized to better experiences. The membership options are designed with the “every traveler” in mind, the ones who only fly once a month or the ones who fly 10 or more flights a month. The low introductory price makes JFE hard to beat, especially when there is no hidden costs involved.'
                            }
                        </p>
                        <p className={'text'}>
                            {'Find out why our solution works for so many people just like you!'}
                        </p>
                        <img
                            src={'images/jfe-brochure.png'}
                            alt={'JustFlyEmpty Membership Brochure'}
                            style={{ marginTop: 30, marginBottom: 20 }}
                        />
                    </div>
                    <div className={'form-container'}>
                        <TextTitle headingLevel={2} title={'Download brochure'} />
                        <p className={'text'}>
                            {
                                'Please enter your contact details and download membership brochure now.'
                            }
                        </p>
                        <MembershipBrochureForm
                            error={error}
                            onSubmitForm={onSubmit}
                            formData={formData}
                            onUpdateForm={onUpdateForm}
                            url={url}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default MembershipTemplate
