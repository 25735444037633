import React from 'react'
import { arrayContains } from '../../../utilities/array'
import ButtonJet from '../../atoms/ButtonJet/ButtonJet'

const JetsPicker = ({ jets, onChange, selected = [] }) => {
    const updateJets = (jet) => {
        if (arrayContains(selected, jet, 'id')) {
            onChange(
                'jets',
                selected.filter((jetItem) => jet.id !== jetItem.id)
            )
            return
        }
        onChange('jets', [...selected, jet])
    }

    return (
        <div>
            <div className={'form-section-title'}>{'Jet type'}</div>
            <div className={'buttons-picker-container'}>
                {Object.values(jets).map((item) => (
                    <ButtonJet
                        item={item}
                        key={item.id}
                        onChange={() => updateJets(item)}
                        isSelected={arrayContains(selected, item, 'id')}
                    />
                ))}
            </div>
        </div>
    )
}
export default JetsPicker
