import React from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as FacebookIcon } from '../../../assets/icons/facebook_icon.svg'
import { ReactComponent as InstagramIcon } from '../../../assets/icons/instagram_icon.svg'
import { ReactComponent as LocationIcon } from '../../../assets/icons/location_icon.svg'
import ArgusImg from '../../../assets/images/argus_img.png'
import EbaaImg from '../../../assets/images/ebaa_img.png'
import NbaaImg from '../../../assets/images/nbaa_img.png'
import WyvernImg from '../../../assets/images/wyvern_img.png'
import { LINK_URL } from '../../../services/api/config'
import TrustPilot from '../../atoms/TrustPilot'

function Footer() {
    const externalLinks = [
        {
            to: 'https://www.wyvernltd.com/',
            image: WyvernImg,
            name: 'Wyvern',
            alt: 'Wyvern LTD'
        },
        { to: 'https://www.ebaa.org/', image: EbaaImg, name: 'Ebaa', alt: 'Ebaa' },
        {
            to: 'https://www.argus.aero/',
            image: ArgusImg,
            name: 'Argus',
            alt: 'Argus'
        },
        { to: 'https://nbaa.org/', image: NbaaImg, name: 'NBAA', alt: 'NBAA' }
    ]
    const renderLinks = () => {
        return (
            <>
                <Link to={'/'} onClick={() => window.scrollTo({ top: 0 })}>
                    <p>{'Home'}</p>
                </Link>
                <Link to={'/daily-offers'} onClick={() => window.scrollTo({ top: 0 })}>
                    <p>{'Our daily offers'}</p>
                </Link>
                <Link to={'/about'} onClick={() => window.scrollTo({ top: 0 })}>
                    <p>{'About us'}</p>
                </Link>
                <Link to={'/subscribe'} onClick={() => window.scrollTo({ top: 0 })}>
                    <p>{'Subscribe'}</p>
                </Link>
            </>
        )
    }
    return (
        <footer>
            <div className={'footer_container'}>
                <div className={'column is-full'} style={{ padding: 0 }}>
                    <a
                        href={LINK_URL}
                        rel={'canonical'}
                        className={'column is-two-fifths '}
                        style={{ padding: 0 }}>
                        <img
                            src={'https://justflyempty.com/images/jfsolutions-logo-wh.svg'}
                            alt={'JustFlyEmpty Logo'}
                            className={'footer-logo'}
                        />
                    </a>
                </div>
                <div className={'columns'} style={{ marginBottom: 0 }}>
                    <div className={'left_container column is-half'}>
                        <div className={'contact_container'}>
                            <div>
                                <h2>{'JustFlySolutions Headquarters'}</h2>
                            </div>
                            <div>
                                <p className={'info_text'}>
                                    {'5963 Biscayne Blvd.'}
                                    <br />
                                    {'Miami, FL 33137 '}
                                    <br />
                                    <a href={'tel:+17862446287'} className={'info_text'}>
                                        {'+1 (786) 244-6287'}
                                    </a>
                                    <br />
                                    <a
                                        href={'mailto:info@justflyempty.com'}
                                        className={'info_text'}>
                                        {'info@justflyempty.com'}
                                    </a>
                                </p>
                            </div>
                        </div>
                        <div>
                            <div className={'footer_menu_mobile'}>{renderLinks()}</div>
                            <div
                                className={
                                    'social_container column  is-two-fifths is-half-desktop is-three-fifths-tablet  is-two-thirds-mobile'
                                }>
                                <a
                                    data-baseweb={'button'}
                                    href={'https://www.facebook.com/justflyempty'}
                                    className={'link'}>
                                    <FacebookIcon />
                                </a>

                                <a
                                    data-baseweb={'button'}
                                    href={'https://instagram.com/justflyempty'}
                                    className={'link'}>
                                    <InstagramIcon />
                                </a>

                                <button
                                    data-baseweb={'button'}
                                    aria-label={
                                        'Seeing information for this city: Miami, Florida, US'
                                    }
                                    id={'location-banner-button'}
                                    className={'button_location'}>
                                    <span className={'icon_location'}>
                                        <LocationIcon />
                                    </span>
                                    <span
                                        id={'location-banner-label'}
                                        className={'location-banner-label'}>
                                        {'Miami, Florida'}
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={'right_container column is-half partners'}>
                        <div className={'footer_menu_desktop'}>{renderLinks()}</div>
                        <TrustPilot />
                        <div className={'partners_product'}>
                            {externalLinks.map((link) => (
                                <a
                                    href={link.to}
                                    target={'_blank'}
                                    rel={'noreferrer'}
                                    key={link.name}>
                                    <img src={link.image} alt={link?.alt} />
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <div className={'links_mobile'}>
                <Link to={'/privacy-policy'} rel={'canonical'} className={'link'}>
                    <p className={'copyright_text'}>{'Privacy Policy'}</p>
                </Link>
                <Link to={'/terms'} className={'link'}>
                    <p className={'copyright_text'}>{'Terms & conditions'}</p>
                </Link>
            </div>
            <div className={'copyright_container'}>
                <div className={'copyright'}>
                    <div>
                        <p className={'copyright_text'}>
                            <span>{'© 2021'}</span>
                            {'JustFlySolutions LLC.'}
                        </p>
                    </div>
                    <div className={'links'}>
                        <Link to={'/privacy-policy'} className={'link'}>
                            <p className={'copyright_text'}>{'Privacy policy'}</p>
                        </Link>
                        <Link to={'/terms'} className={'link'}>
                            <p className={'copyright_text'}>{'Terms & conditions'}</p>
                        </Link>
                    </div>
                </div>
            </div>
            <div className={'info'}>
                <p>
                    {
                        'JustFlyEmpty Jets is a charter broker with a global digital jet charter market '
                    }
                    {'place, and is not a direct air carrier. All flights advertised to which '}
                    {'JustflyEmpty has access are owned and operated by a properly licensed third '}
                    {
                        'party air carrier with Part 135 and Part 121 authority from the Federal Aviation '
                    }
                    {'Administration (or other appropriate authority under US or foreign law) and '}
                    {'Department of Transportation. JustFlyEmpty Jets is fully compliant with US '}
                    {
                        'Department of Transportation part 295 and 298 Charter Broker rules. JustFlyEmpty '
                    }
                    {
                        'Jets does not carry additional liability insurance. Passengers are covered by '
                    }
                    {'aircraft operator insurance.'}
                </p>
            </div>
        </footer>
    )
}

export default Footer
