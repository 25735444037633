import React from 'react'
import { Helmet } from 'react-helmet-async'
import HeaderTitle from '../../atoms/HeaderTitle/HeaderTitle'
import MotoText from '../../atoms/MotoText/MotoText'
import PrimaryButton from '../../atoms/PrimaryButton/PrimaryButton'
import TextTitle from '../../atoms/TextTitle/TextTitle'
import HeroSection from '../../molecules/HeroSection/HeroSection'
import ModalBox from '../../molecules/ModalBox/ModalBox'
import InquireEmptyLegForm from './components/inquireEmptyLegForm'
import RequestEmptyLegForm from './components/requestEmptyLegForm'
import EmptyLegsTemplate from './EmptyLegsTemplate'

const DailyOffersTemplate = ({
    dailyOffers,
    formData,
    history,
    onPress,
    activeLeg,
    errors,
    onUpdateForm,
    isStoredSuccessfully,
    responseData,
    onChangePage
}) => {
    return (
        <>
            <HeaderTitle title={'Daily offers'} />
            <Helmet>
                <title>{'Daily offers - JustFlyEmpty'}</title>
            </Helmet>
            <div className={'daily-offers-container'}>
                <div className={'container'}>
                    <MotoText
                        title={'ANY JET. ANY TIME. ANYWHERE.'}
                        titleTop={7}
                        marginTop={20}
                        marginBottom={20}
                    />
                </div>
                <EmptyLegsTemplate
                    dailyOffers={dailyOffers}
                    onPress={onPress}
                    responseData={responseData}
                    onChangePage={onChangePage}
                />
                <div className={'container'}>
                    <div className={'subscribe-teaser'}>
                        <p className={'subscribe-form subscribe-teaser'}>
                            <span>{'Sign up to start receiving them daily in your inbox.'}</span>
                        </p>
                        <PrimaryButton
                            title={'Subscribe'}
                            paddingType={'small'}
                            onClick={() => {
                                window.scrollTo({ top: 0 })
                                history.push('/subscribe')
                            }}
                        />
                    </div>
                </div>
            </div>
            {isStoredSuccessfully === null && activeLeg && (
                <ModalBox
                    onClickPrimary={() => onPress('confirmed')}
                    primaryAction={'Submit'}
                    onClickSecondary={() => onPress('cancelled')}
                    secondaryAction={'Cancel'}>
                    <div>
                        {activeLeg.type === 'inquire' && (
                            <InquireEmptyLegForm
                                activeLeg={activeLeg}
                                formData={formData}
                                onSubmit={onPress}
                                onUpdateForm={onUpdateForm}
                                errors={errors}
                            />
                        )}
                        {activeLeg.type === 'request-quote' && (
                            <RequestEmptyLegForm
                                activeLeg={activeLeg}
                                formData={formData}
                                onSubmit={onPress}
                                onUpdateForm={onUpdateForm}
                            />
                        )}
                    </div>
                </ModalBox>
            )}
            {isStoredSuccessfully !== null && (
                <ModalBox
                    onClickPrimary={() => onPress(isStoredSuccessfully ? 'ok' : 'try-again')}
                    primaryAction={isStoredSuccessfully ? 'Ok' : 'Try again'}
                    onClickSecondary={() => onPress('cancelled')}
                    secondaryAction={isStoredSuccessfully !== true && 'Cancel'}>
                    <div>
                        <TextTitle
                            headingLevel={2}
                            title={isStoredSuccessfully ? 'Inquire sent!' : `Inquire flight error`}
                        />
                        {isStoredSuccessfully ? (
                            <p
                                style={{
                                    fontWeight: '600',
                                    fontSize: '0.95rem',
                                    lineHeight: '130%'
                                }}>
                                {
                                    'Thank you for requesting an empty leg, one of our agents will get back to you shortly!'
                                }
                                <br /> {'Thank you for trusting us!'}
                            </p>
                        ) : (
                            <p>
                                {'Saving request failed.'}
                                <br />
                                {'Please try again.'}
                            </p>
                        )}
                    </div>
                </ModalBox>
            )}
            <HeroSection
                backImage={'business-hero.webp'}
                style={{ height: 200, backgroundPosition: 'center -200' }}
            />
        </>
    )
}

export default DailyOffersTemplate
