import React from 'react'
import { withRouter } from 'react-router-dom'
import HowItWorksTemplate from '../../components/templates/dailyOffers/HowItWorksTemplate'
import { filterAirportsApi, onSendFormRequest } from '../../services/api/airportsSearchApi'
import { arrayContains } from '../../utilities/array'
import { isFlightValid } from '../../utilities/flight'

// import { encodeStringToUrl } from '../../utilities/string'

class DailyOffersHelpScene extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            formData: {}
        }
    }

    onUpdateForm = async (key, value) => {
        const { formData } = this.state
        const data = { ...formData }
        if (value?.length > 2 && arrayContains(['from', 'to'], key)) {
            data.filteredAirports = await filterAirportsApi(key, formData)
        }
        data.errors = [...(data.errors || []).filter((error) => key !== error)]
        data[key] = value
        this.setState({ formData: data })
    }

    onSubmitForm = async () => {
        const { formData, history } = this.state

        const { isValid, errors } = isFlightValid(formData, ['from', 'to'])

        this.setState({ formData: { ...formData }, errors })
        if (isValid) {
            onSendFormRequest(formData, history)
        }
    }

    render() {
        const { history } = this.props
        const { formData, errors } = this.state

        return (
            <HowItWorksTemplate
                formData={formData}
                onUpdateForm={this.onUpdateForm}
                onSubmitForm={this.onSubmitForm}
                history={history}
                errors={errors}
            />
        )
    }
}

export default withRouter(DailyOffersHelpScene)
