// import { toast } from 'react-toastify'

const ApiAgentMiddleware = () => (next) => async (action) => {
    // const { type = '' } = action
    // switch (type) {
    //
    //     default:
    //         break
    //
    // }
    next(action)
}

export default ApiAgentMiddleware
