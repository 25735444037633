import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import HomeTemplate from '../../components/templates/homeTemplate/HomeTemplate'

class HomeScene extends Component {
    componentDidMount() {
        document.title = 'Welcome! - JustFlyEmpty'
    }

    onclick = (title) => {
        switch (title) {
            case 'justflyprivate':
                window.scrollTo({ top: 700, behavior: 'smooth' })
                break
            default:
                break
        }
    }

    render() {
        const urlSearchParams = new URLSearchParams(window.location.search)
        const { history } = this.props
        const params = Object.fromEntries(urlSearchParams.entries())

        return <HomeTemplate onClick={this.onclick} searchParams={params} history={history} />
    }
}

const mapStateToProps = () => ({})

const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HomeScene))
