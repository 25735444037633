import React from 'react'
import { Helmet } from 'react-helmet-async'
import { ReactComponent as Pig } from '../../assets/icons/pig.svg'
import privateJet from '../../assets/images/private-jet-icon.png'
import jetPrivatePlan from '../../assets/images/jet-private-plane.svg'
import { ReactComponent as PersonalIcon } from '../../assets/images/profile-icon.svg'
import { ReactComponent as Security } from '../../assets/images/security.svg'
import HeaderTitle from '../../components/atoms/HeaderTitle/HeaderTitle'
import MotoText from '../../components/atoms/MotoText/MotoText'
import TextTitle from '../../components/atoms/TextTitle/TextTitle'
import CardAbout from './components/CardAbout.js/CardAbout'

function AboutUsScene() {
    return (
        <>
            <HeaderTitle title={'About us'} />
            <Helmet>
                <title>{'About us - JustFlyEmpty'}</title>
            </Helmet>
            <div className={'container about-us-container'}>
                <MotoText
                    title={'ANY JET. ANY TIME. ANYWHERE.'}
                    titleTop={7}
                    marginBottom={20}
                    marginTop={20}
                />
                <TextTitle headingLevel={2} title={'Why Choose JustFlyEmpty?'} />
                <div className={'choose-container'}>
                    <div className={'text-container'}>
                        <p className={'text'}>
                            {'Time is money. Time is for making memories. Time well spent is worth every penny and cent. ' +
                                'We tailor your private charter flight to allow you to make the most of every moment. ' +
                                'Airports these days are filled with long queues of frustrated travelers, elbowing each other to sit in a cramped seat and eager for it all to be over as soon as possible.'}
                        </p>
                        <p className={'text'}>
                            {
                                'We truly believe the journey can be just as good as (even better than) the destination. '
                            }
                        </p>
                        <div className={'img-container'} />
                        <p className={'text'}>
                            <strong>{'JustFlySolutions'}</strong>
                            {
                                ' was born out of a vision to provide the luxury, convenience, safety, security and stress-free flight that only jet flight can offer.'
                            }
                        </p>
                        <p className={'text'}>
                            {
                                'With us, you are free from the pricey pitfalls of owning your own private aircraft and the unreliability of shared ownership models. Our business model means that you are only paying for your individual flight – at a very reasonable rate.'
                            }
                        </p>
                        <p className={'text'}>{'So come fly with us - your jet awaits.'}</p>
                    </div>
                    <div className={'img-container'} />
                </div>
                <div>
                    <TextTitle
                        headingLevel={2}
                        title={'The Stats And Figures That Tell Our Story'}
                    />
                    <div className={'cards_container'}>
                        <CardAbout
                            title={'+ 5,000'}
                            subtitle={' planes'}
                            description={'for you to choose'}
                            image={jetPrivatePlan}>
                            {/* <Plane /> */}
                        </CardAbout>
                        <CardAbout
                            title={'+ 14,000'}
                            subtitle={' airports'}
                            description={'at your disposal'}
                            image={privateJet}
                        />
                        <CardAbout
                            title={'24/7'}
                            subtitle={'Comprehensive service'}
                            description={'fast & trustwothy, efficient response time'}>
                            <PersonalIcon />
                        </CardAbout>
                        <CardAbout
                            title={'Best'}
                            subtitle={' price offer'}
                            description={' trusted by clients & operators'}>
                            <Pig />
                        </CardAbout>
                        <CardAbout
                            title={'Security'}
                            subtitle={' and safety'}
                            description={'Your safe trip is our priority'}>
                            <Security />
                        </CardAbout>
                    </div>
                    <ul className={'about-reasons'}>
                        <li>
                            {'100% ATTENTION TO DETAIL AND COMPLETE FLEXIBILITY FOR EVERY TRIP'}
                        </li>
                        <li>{'24/7 DEDICATED ACCOUNT MANAGER'}</li>
                        <li>
                            {'DEPARTURES FROM '}&amp;
                            {' TO AIRFIELDS WHERE COMMERCIAL FLIGHTS DO NOT HAVE ACCESS'}
                        </li>
                        <li>{'FLIGHTS ADJUSTED TO YOUR SCHEDULE'}</li>
                        <li>{'EXCLUSIVE CATERING AT YOUR DISPOSAL'}</li>
                        <li>{'GROUND TRANSPORTATION'}</li>
                    </ul>
                    <MotoText
                        title={"Just fly. That's the solution."}
                        titleTop={7}
                        marginBottom={20}
                        marginTop={20}
                    />
                </div>
            </div>
        </>
    )
}

export default AboutUsScene
