import React from 'react'
import { Helmet } from 'react-helmet-async'
import HeaderTitle from '../../atoms/HeaderTitle/HeaderTitle'
import MotoText from '../../atoms/MotoText/MotoText'
import TextTitle from '../../atoms/TextTitle/TextTitle'

const OurStandardsTemplate = () => {
    return (
        <>
            <HeaderTitle title={'Our standards'} />
            <Helmet>
                <title>{'Our standards - JustFlyEmpty'}</title>
            </Helmet>
            <div className={'container our-standards'}>
                <MotoText title={'UNPARALLELED SAFETY STANDARDS'} titleTop={7} marginBottom={20} />
                <TextTitle>{'Our safety standards'}</TextTitle>
                <div className={'columns'}>
                    <div className={'column is-half'}>
                        <p>
                            {'At '} <strong>{'JustFlyEmpty '}</strong>
                            {
                                'your safety is our highest priority. This is why we voluntarily submit to industry leading safety organizations such as ARG/US and have incorporated Safety Management Systems (SMS) company-wide. '
                            }
                        </p>
                        <p>
                            {
                                'These measures are all part of the processes incorporated in our Safety Management System (SMS) to ensure industry leading safety practices.'
                            }
                        </p>
                    </div>
                    <div className={'column is-half'}>
                        <p>
                            {'At '}
                            <strong>{'JustFlyEmpty'}</strong>
                            {", each flight that's released "}
                            {'undergoes a flight risk assessment that scores: '}
                        </p>
                        <ul className={'list'}>
                            <li>
                                <p>{'CREW PAIRING, '}</p>
                            </li>
                            <li>
                                <p>{'FATIGUE, '}</p>
                            </li>
                            <li>
                                <p>{'COMPLEX AIRPORTS/AIRSPACE, '}</p>
                            </li>
                            <li>
                                <p>{'TIME ZONE DIFFERENCES, etc.'}</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <MotoText title={'EXPERT PILOTS FLYING THE BEST JETS'} titleTop={7} />
                <TextTitle>{'You’re in Good Hands'}</TextTitle>
                <div className={'columns'}>
                    <div className={'column'}>
                        <p>
                            {
                                'Each pilot undergoes a three-step interview process and complete background check and possesses an average of 7500 hours of flight experience. All pilots exceed FAA minimum standards and conduct Level C and D simulator-based training every six months.'
                            }
                        </p>
                    </div>
                </div>

                <MotoText title={'MAINTAINING THE HIGHEST STANDARDS'} titleTop={7} />
                <TextTitle>{'Safety Certifications'}</TextTitle>
                <p>
                    {
                        'Our focus on safety is evident by our voluntary membership to the industry’s most recognized organizations. '
                    }
                    <br />
                    {'Current safety ratings include: '}
                </p>
                <div className={'columns'}>
                    <div className={'column is-half rows'}>
                        <img src={'/images/argus-emblem.png'} alt={''} />
                        <p>
                            {'The '}
                            <strong>{'ARG/US Ratings '}</strong>
                            {
                                ' due diligence system offers commercial operators, buyers of charter and passengers around the world the confidence and peace-of-mind desired for their upcoming flight. '
                            }
                        </p>
                    </div>
                    <div className={'column is-half rows'}>
                        <img src={'/images/wyvern.jpg'} alt={''} />
                        <p>
                            <strong>{'Wyvern'}</strong>
                            {
                                ' is an independent business that ensures operators are using stringent practices. As its greatest level of ranking, Wyvern provides the Wingman certification. At JustFlyEmpty, we are proud of this certification which demonstrates that we’ve gone the extra mile in security and quality. '
                            }
                        </p>
                    </div>
                </div>
                <MotoText title={'JUST FLY. THAT’S THE SOLUTION'} marginBottom={20} titleTop={7} />
            </div>
        </>
    )
}

export default OurStandardsTemplate
