import React from 'react'
import { Helmet } from 'react-helmet-async'
import HeaderTitle from '../../../components/atoms/HeaderTitle/HeaderTitle'
import InputField from '../../../components/atoms/InputField/InputField'
import MotoText from '../../../components/atoms/MotoText/MotoText'
import TextTitle from '../../../components/atoms/TextTitle/TextTitle'
import FormFooter from '../../../components/molecules/FormFooter/FormFooter'
import HeroSection from '../../../components/molecules/HeroSection/HeroSection'
import ModalAlert from '../../../components/molecules/ModalАlert/ModalAlert'
import { LINK_URL } from '../../../services/api/config'
import { arrayContains } from '../../../utilities/array'
import { isFieldValid } from '../../../utilities/form'

const SubscribeTemplate = ({ status, onSendForm, state, onCloseModal }) => {
    const fields = [
        { key: 'name', placeholder: 'Full name', errorMessage: 'Please enter full name' },
        { key: 'email', placeholder: 'Email address', errorMessage: 'Email address is not valid' },
        { key: 'phone', placeholder: 'Phone number', errorMessage: 'Phone number is not valid' }
    ]
    return (
        <>
            <Helmet>
                <title>{'Subscribe for offers - JustFlyEmpty'}</title>
                <link rel={'canonical'} href={`${LINK_URL}subscribe`} />
            </Helmet>
            <HeaderTitle title={'Subscribe for offers'} />
            <div className={'container subscribe-for-offers'}>
                <MotoText
                    title={'FLY. EXPLORE. UNLOCK THE WORLD. ADVENTURE AWAITS.'}
                    titleTop={7}
                    marginTop={20}
                    marginBottom={20}
                />
                <div className={'columns'}>
                    <div className={'column is-half'}>
                        <TextTitle headingLevel={2}>
                            {'Comfort. Convenience. Cleanliness'}
                        </TextTitle>
                        <p>
                            {
                                'Every flight with JustFlyEmpty is tailored to meet your travel needs. '
                            }
                            <br />
                            {
                                'In addition to the benefit of avoiding long queues and crowded terminals, our clients enjoy the benefit of flight that is '
                            }
                            <strong>
                                {
                                    'customized to their schedule, destination, protocol and other requirements.'
                                }
                            </strong>
                        </p>
                        <p>
                            {
                                'JustFlyEmpty offers flights that also operate on airfields not used by commercial aircraft for greater coverage and flexibility.'
                            }
                        </p>
                        <p>
                            {
                                'We also offer unique membership benefits such as last-minute cancellations, 24/7 client support and unrivalled attention to detail when personalizing your private plane.'
                            }
                        </p>
                    </div>
                    <div className={`column ${window.innerWidth > 600 ? 'is-half' : ''}`}>
                        <TextTitle headingLevel={3}>{'JET-SET-GO!'}</TextTitle>
                        <p>
                            {
                                'Ready to fly? Read about how to properly book a private charter flight, discover our insider tips on what to look out for and receive our daily offers for maximum savings.\n'
                            }
                        </p>
                        {state.status !== 'success' && (
                            <p>
                                {
                                    'Please fill in your personal information to complete the request:'
                                }
                            </p>
                        )}

                        {state.status !== 'success' && (
                            <form onSubmit={onSendForm} method={'post'}>
                                {fields.map(({ name, key, placeholder, errorMessage = '' }) => (
                                    <InputField
                                        placeholder={placeholder}
                                        key={key}
                                        name={name}
                                        id={key}
                                        onChange={() => null}
                                        isInvalid={
                                            state.shouldValidate &&
                                            arrayContains(state?.errors, key)
                                        }
                                        errorMessage={
                                            state.shouldValidate &&
                                            !isFieldValid(key, state[key]) &&
                                            errorMessage
                                        }
                                    />
                                ))}
                                <FormFooter
                                    primaryActionTitle={
                                        status === 'sending' ? 'Sending...' : 'Submit'
                                    }
                                    cancelActionTitle={'Cancel'}
                                    onCancel={() => null}
                                    status={status}
                                />
                            </form>
                        )}
                    </div>
                </div>
                <MotoText
                    title={"JUST FLY. THAT'S THE SOLUTION"}
                    titleTop={7}
                    marginTop={20}
                    marginBottom={20}
                />
            </div>
            <HeroSection
                backImage={'business-hero.webp'}
                style={{ height: 200, backgroundPosition: 'center -200' }}
            />
            {status === 'success' && (
                <ModalAlert
                    title={'THANK YOU'}
                    message={'Thank you for subscribing!'}
                    secondaryAction={'Close'}
                    onClickSecondary={onCloseModal}
                />
            )}
        </>
    )
}

export default SubscribeTemplate
