// eslint-disable-next-line import/prefer-default-export
export const getQueryStringParams = (query) => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query).split('&').reduce((params, param) => {
              const [key, value] = param.split('=')
              // eslint-disable-next-line no-param-reassign
              params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : ''
              return params
          }, {})
        : {}
}
