import React from 'react'

const CheckButton = ({ isSelected, id, name, onChange }) => (
    <button
        className={isSelected ? 'selected' : ''}
        disabled={isSelected}
        onClick={(e) => {
            e.preventDefault()
            onChange('charterType', id)
        }}
        onChange={(e) => {
            e.preventDefault()
            onChange('charterType', id)
        }}>
        {name}
    </button>
)

const CheckPicker = ({ selected = 'one-way', onChange = () => null }) => (
    <div className={'trip-picker-container'}>
        {[
            { name: 'One way', id: 'one-way' },
            { name: 'Round trip', id: 'round-trip' }
        ].map((item) => (
            <CheckButton
                isSelected={selected === item.id}
                name={item.name}
                key={item.id}
                onChange={onChange}
                id={item.id}
            />
        ))}
    </div>
)

export default CheckPicker
