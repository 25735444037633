import React from 'react'
import { emptyLegData } from '../../../utilities/dailyOffers'
import { formattedDate } from '../../../utilities/date'

const EmptyLegItem = ({ emptyLeg, onPress }) => {
    const data = emptyLegData(emptyLeg)
    return (
        <div className={'empty-leg-container'}>
            {data?.plane && (
                <div
                    className={'plane-container'}
                    style={
                        data?.planeImage
                            ? {
                                  background: 'url(./images/p180susi.jpg) no-repeat center 70%'
                              }
                            : {}
                    }>
                    <div
                        className={'plane-info'}
                        style={!data?.planeImage ? { marginTop: 0 } : null}>
                        <h2>{data?.plane?.split('/')[0]}</h2>
                        {data?.planeCategory && (
                            <p className={'plane-category'}>{data?.planeCategory}</p>
                        )}
                    </div>
                    <div className={'separator-container'}>
                        <div className={'separator'} style={{ backgroundColor: 'white' }} />
                    </div>
                </div>
            )}
            <div className={`padding-container small-top-padding`}>
                <div className={'route'}>
                    <div>
                        <p>{data.from}</p>
                    </div>

                    <div className={'route-separator'} />
                    <div>
                        <p>{data.to}</p>
                    </div>
                </div>
                <div className={'route'}>
                    <div>
                        <span>{data.fromLocation}</span>
                    </div>
                    <div>
                        <span>{data.toLocation}</span>
                    </div>
                </div>
                <div className={'separator'} />
                <div className={'leg-footer'}>
                    <span className={'date'}>
                        {formattedDate(data?.date)}
                        <span className={'super'}>{'th'}</span>
                    </span>
                    <span className={'users'}>{`PAX ${data?.pax || 5}`}</span>
                </div>
            </div>
            <div className={'buttons'}>
                <button onClick={() => onPress('inquire', emptyLeg)}>{'Inquire flight'}</button>
                <button onClick={() => onPress('request-quote', emptyLeg)}>
                    {'Request quote'}
                </button>
            </div>
        </div>
    )
}

export default EmptyLegItem
