// import airportData from '../data/airports.json'

import { BASE_URL } from '../services/api/config'

export const airportsMap = (airports) =>
    airports.map(
        (airport) =>
            airport && {
                value: `${airport?.name?.trim()}, ${
                    airport?.city !== airport.name
                        ? airport?.city?.trim()
                        : airport?.country?.trim()
                } (${airport?.code})`,
                label: `${airport?.code}: ${airport?.name?.trim()}${
                    airport?.name?.length < 10 && airport?.city !== airport.name
                        ? `, ${airport?.city?.trim()}`
                        : ''
                }`
            }
    )

export const filterAirports = async (id = 'from', form) => {
    if (id !== 'from' && id !== 'to') {
        return []
    }
    const airportSearch = form[id]
    const response = await fetch(`${BASE_URL}/api/airports/search?search=${airportSearch}`)
    const { airports } = await response.json()

    const filteredAirports = form.filteredAirports || {}
    filteredAirports[id] = airportsMap(airports)
    return filteredAirports
}
