import React from 'react'

const HeroContainer = ({
    children,
    backImage,
    lowBackImage,
    className,
    containerClassName,
    style,
    borders
}) => {
    const backStyle = () => {
        let backImageLink = backImage ? `url(images/${backImage})` : ''
        if (lowBackImage) {
            backImageLink = `${backImageLink} url(images/${lowBackImage})`
        }

        return {
            backgroundImage: backImageLink,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            ...style
        }
    }

    return (
        <div
            className={`hero-container ${
                borders ? borders.map((border) => ` ${border}-border `) : ''
            } ${className}`}
            style={backStyle()}>
            <div className={`container ${containerClassName || ''}`}>{children}</div>
        </div>
    )
}
export default HeroContainer
