import React from 'react'
import HeaderTitle from '../../atoms/HeaderTitle/HeaderTitle'
import CharterContactForm from './CharterContactForm'

const CharterInquiryTemplate = ({ params }) => {
    const { from, to, jets } = params
    return (
        <>
            <HeaderTitle title={'Book a charter'} />
            <div className={'container charter-inquiry-container'}>
                <div className={'columns'}>
                    <div className={'column book-a-charter-img'} />
                    <div className={'column'}>
                        <CharterContactForm from={from} to={to} jets={jets?.split(',') || []} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default CharterInquiryTemplate
