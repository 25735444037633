import React from 'react'
// import { Link } from 'react-router-dom';

const NotFoundTemplate = () => {
    return (
        <div>
            <h1>{'404 Not found'}</h1>
        </div>
    )
}
export default NotFoundTemplate
