import React from 'react'
import HeroContainer from '../../atoms/HeroContainer/HeroContainer'
import TextTitle from '../../atoms/TextTitle/TextTitle'

const HeroSection = ({ backImage, title, subtitle, style }) => {
    return (
        <HeroContainer
            backImage={backImage}
            className={'hero-section'}
            borders={['top', 'bottom']}
            style={style}>
            {/* //     <img */}
            {/* //         src={`images/${backImage}`} */}
            {/* //         alt={''} */}
            {/* //         style={{ width: 100, height: 100, display: 'block', background: 'red' }} */}
            {/* //     /> */}
            <div style={{ alignSelf: 'center', margin: 'auto', marginBottom: '2rem' }}>
                <TextTitle headingLevel={2} title={title} />
                <p>{subtitle}</p>
            </div>
        </HeroContainer>
    )
}

export default HeroSection
