import React from 'react'
import { Helmet } from 'react-helmet-async'
import HeaderTitle from '../../atoms/HeaderTitle/HeaderTitle'
import MotoText from '../../atoms/MotoText/MotoText'
import PrimaryButton from '../../atoms/PrimaryButton/PrimaryButton'
import TextTitle from '../../atoms/TextTitle/TextTitle'
import FlightForm from '../../molecules/FlightForm/FlightForm'
import FormFooter from '../../molecules/FormFooter/FormFooter'
import HeroSection from '../../molecules/HeroSection/HeroSection'

const HowItWorksTemplate = ({ history, onSubmitForm, formData, onUpdateForm, errors }) => {
    return (
        <>
            <HeaderTitle title={'Daily Offers - How it works?'} />
            <Helmet>
                <title>{'Daily Offers - How it works?'}</title>
            </Helmet>
            <div className={'daily-offers-container'}>
                <div className={'container how-it-works'}>
                    <div className={'container'}>
                        <MotoText
                            title={'FLY. EXPLORE. UNLOCK THE WORLD. ADVENTURE AWAITS.'}
                            titleTop={7}
                            marginTop={20}
                            marginBottom={20}
                        />
                        <TextTitle headingLevel={2}>{'How it works'}</TextTitle>
                        <div className={'columns'}>
                            <div className={'column'}>
                                <p>
                                    {
                                        'Did you know that hundreds of private jets travel across our skies every day with no passengers aboard? '
                                    }{' '}
                                </p>
                                <p>
                                    {
                                        'This is because they are often relocated between flights and destinations. '
                                    }
                                    <br />
                                    {
                                        'Private jets were always reserved for the crème-de-la-crème, but we give you access to these amazing aircraft and take advantage of the ‘empty legs’ to secure a great price for your jet.'
                                    }
                                </p>
                                <p className={'subscribe-form'}>
                                    <PrimaryButton
                                        title={'View active offers'}
                                        paddingType={'small'}
                                        onClick={() => history.push('/daily-offers')}
                                    />
                                    <PrimaryButton
                                        title={'Subscribe'}
                                        paddingType={'small'}
                                        type={'wired'}
                                        onClick={() => history.push('/subscribe')}
                                        style={{ fontWeight: 'bold', marginLeft: 10 }}
                                    />
                                </p>
                                <p>
                                    <span>
                                        {'Sign up to start receiving them daily in your inbox.'}
                                    </span>
                                </p>
                            </div>
                            <div className={'column'}>
                                <div className={'columns daily-form-container'}>
                                    <div className={'column is-half'}>
                                        <FlightForm
                                            formData={formData}
                                            onUpdateForm={onUpdateForm}
                                            useJets={false}
                                            shouldShowErrors
                                            errors={errors}
                                        />
                                        <FormFooter
                                            primaryActionTitle={'Send request'}
                                            onClick={onSubmitForm}
                                        />
                                    </div>
                                    <div className={'column is-half centered'}>
                                        <img src={'/images/private-jet.png'} alt={'Daily offers'} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <HeroSection
                backImage={'business-hero.webp'}
                style={{ height: 200, backgroundPosition: 'center -200' }}
            />
        </>
    )
}

export default HowItWorksTemplate
