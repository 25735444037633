import React from 'react'

const MotoText = ({
    title,
    background = 'white',
    marginTop = 10,
    titleTop = 0,
    marginBottom = 0
}) => {
    return (
        <div style={{ marginTop, marginBottom }}>
            <div className={'moto-container'}>
                <p style={{ background }}>
                    <span style={{ top: titleTop }}>{title}</span>
                </p>
                <div className={'line'} />
            </div>
        </div>
    )
}
export default MotoText
