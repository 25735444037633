export const emptyLegData = (emptyLeg) => {
    const { from_airport: fromAirport, to_airport: toAirport } = emptyLeg
    return {
        ...emptyLeg,
        fromLocation: `${fromAirport.name}, ${fromAirport?.city}`,
        toLocation: `${toAirport.name}, ${toAirport?.city}`
    }
}

export const test = []
