/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { ReactComponent as PersonalIcon } from '../../../assets/icons/personal_icon.svg'
import { ReactComponent as HamburgerIcon } from '../../../assets/images/hambMenu.svg'
import handshakeIcon from '../../../assets/images/handshake.webp'
import logo from '../../../assets/images/justflyempty-logo.webp'
import phoneIcon from '../../../assets/images/phone-icon.webp'
import { LINK_URL } from '../../../services/api/config'
import MobileMenu from './MobileMenu'

const TopMenu = ({ scroll }) => {
    const links = [
        { name: 'Daily offers', path: 'daily-offers' },
        { name: 'Subscribe', path: 'subscribe' },
        { name: 'Membership', path: 'membership' },
        { name: 'About us', path: 'about' }
    ]

    const [isPopupMenuVisible, setPopupMenuVisible] = useState(false)

    return (
        <div>
            <nav
                className={
                    scroll > 5
                        ? 'header_container column is-full fixed_header'
                        : 'header_container column is-full'
                }>
                <div className={'content_navigation'}>
                    <ul>
                        <li>
                            <a href={`${LINK_URL}`} rel={'canonical'} className={'logo_container'}>
                                <img src={logo} alt={'logo'} className={'logo'} />
                            </a>
                        </li>

                        {links.map((link) => (
                            <li key={link.path} className={'link left-menu-link'}>
                                <a
                                    href={`${LINK_URL}${link.path}`}
                                    className={'name_link'}
                                    rel={'canonical'}>
                                    {link.name}
                                </a>
                            </li>
                        ))}
                    </ul>
                    <ul className={'right-menu'}>
                        <li>
                            <a href={`${LINK_URL}signup/agent`} rel={'canonical'}>
                                <button className={'button'}>
                                    <img src={handshakeIcon} alt={'handshake'} className={'img'} />
                                </button>
                                <span className={'text'}>{'Become our partner'}</span>
                            </a>
                        </li>
                        <li>
                            <a href={'tel:+17862446287'}>
                                <button className={'button'}>
                                    <img src={phoneIcon} alt={'handshake'} className={'img'} />
                                </button>
                                <span className={'text'}>{'+1 (786) 244 6287'}</span>
                            </a>
                        </li>
                        <li>
                            <a
                                href={'https://master.justflyempty.com'}
                                target={'_blank'}
                                rel={'noreferrer'}>
                                <button className={'button'}>
                                    <PersonalIcon />
                                </button>
                                <span>{'Login'}</span>
                            </a>
                        </li>
                    </ul>

                    <div className={'mobile-nav'}>
                        {isPopupMenuVisible ? (
                            <button
                                onClick={() => setPopupMenuVisible(!isPopupMenuVisible)}
                                className={'button-close'}>
                                {'X'}
                            </button>
                        ) : (
                            <button onClick={() => setPopupMenuVisible(!isPopupMenuVisible)}>
                                <HamburgerIcon />
                            </button>
                        )}
                    </div>
                </div>
            </nav>
            <MobileMenu
                setPopupMenuVisible={setPopupMenuVisible}
                isPopupMenuVisible={isPopupMenuVisible}
            />
        </div>
    )
}
export default TopMenu
