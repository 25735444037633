import React from 'react'
import MotoText from '../../atoms/MotoText/MotoText'
import PrimaryButton from '../../atoms/PrimaryButton/PrimaryButton'

const ModalBox = ({
    title,
    children,
    primaryAction,
    secondaryAction,
    onClickPrimary,
    onClickSecondary
}) => {
    return (
        <div className={'modal-alert-container'}>
            <div className={'modal-alert-content'}>
                <MotoText
                    title={title}
                    titleTop={7}
                    marginTop={20}
                    marginBottom={0}
                    background={'#c9c9c9'}
                />
                {children}
                <div className={'action-footer'}>
                    {primaryAction && (
                        <PrimaryButton
                            title={primaryAction}
                            type={'primary'}
                            paddingType={'small'}
                            onClick={onClickPrimary}
                        />
                    )}
                    {secondaryAction && (
                        <PrimaryButton
                            title={secondaryAction}
                            type={'cancel'}
                            paddingType={'small'}
                            onClick={onClickSecondary}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default ModalBox
