import React from 'react'
import Pagination from 'react-js-pagination'
import { LINK_URL } from '../../../services/api/config'
import TextTitle from '../../atoms/TextTitle/TextTitle'
import EmptyLegItem from '../../molecules/DailyOffer/EmptyLegItem'

const EmptyLegsTemplate = ({ dailyOffers = [], onPress, responseData, onChangePage }) => {
    const renderWeb = () => {
        return (
            (typeof dailyOffers === 'object' &&
                dailyOffers?.map((emptyLeg) => (
                    <EmptyLegItem key={`${emptyLeg.id}`} emptyLeg={emptyLeg} onPress={onPress} />
                ))) ||
            null
        )
    }

    const {
        current_page: activePage,
        per_page: perPage,
        total,
        from,
        last_page: lastPage
    } = responseData
    return (
        <div className={'empty-legs-page-container'}>
            <div className={'container'}>
                <TextTitle>
                    {'Available empty legs'}{' '}
                    <a href={`${LINK_URL}how-it-works`}>{'How it works?'}</a>
                </TextTitle>
            </div>
            <div className={'empty-legs-wrapper'}>{renderWeb()}</div>
            {from !== lastPage && (
                <Pagination
                    activePage={activePage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={total}
                    pageRangeDisplayed={5}
                    onChange={onChangePage}
                />
            )}
        </div>
    )
}

export default EmptyLegsTemplate
