import React from 'react'

const PrimaryButton = ({
    title = '',
    type = 'primary',
    children,
    onClick,
    style,
    paddingType = 'large',
    disabled = false
}) => (
    <button
        className={`${type || 'primary'}-btn ${paddingType}-padding `}
        onClick={onClick}
        disabled={disabled}
        style={style}>
        {title || children || ''}
    </button>
)

export default PrimaryButton
