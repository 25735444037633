import React from 'react'
import { Helmet } from 'react-helmet-async'
import { LINK_URL } from '../../../services/api/config'
import HeaderTitle from '../../atoms/HeaderTitle/HeaderTitle'
import MotoText from '../../atoms/MotoText/MotoText'
import TextTitle from '../../atoms/TextTitle/TextTitle'
import logo from '../../../assets/images/logo-horizontal-black.png'

const EmptyLegInquiryConfirmationTemplate = ({ params }) => {
    const { status = 'error' } = params || {}
    const renderSuccess = () => {
        return (
            <div className={'column is-two-thirds'} style={{ paddingTop: 20 }}>
                <TextTitle headingLevel={2}>{'Confirmed Successfully'}</TextTitle>
                <p style={{ maxWidth: '80%' }}>
                    {'Our team will contact you shortly with additional info about '}
                    {'your requested empty leg flight.'}
                    <br />
                    {'Thank you!'}
                </p>
                <p
                    style={{
                        fontWeight: 'bold',
                        fontSize: '1.2rem',
                        marginRight: '0.5rem'
                    }}>
                    <img
                        src={logo}
                        alt={'JustFlySolutions logo'}
                        style={{ maxWidth: 160, position: 'relative', top: -2 }}
                    />
                    {'Team'}
                </p>
            </div>
        )
    }
    const renderError = () => {
        return (
            <div className={'column is-two-thirds'} style={{ paddingTop: 20 }}>
                <TextTitle headingLevel={2}>{'Oh, something went wrong'}</TextTitle>
                <p style={{ maxWidth: '80%' }}>
                    {
                        'You probably already confirmed your inquiry or requested empty leg is not available anymore.'
                    }
                    <br />
                    {'Can you please re-submit your request or choose another empty leg instead?'}
                    <br />
                    <br />
                    {'Thank you!'}
                </p>
                <br />
                <a
                    href={`${LINK_URL}daily-offers`}
                    rel={'canonical'}
                    className={'primary-btn medium-padding'}>
                    {'Get Daily offers'}
                </a>
            </div>
        )
    }

    return (
        <>
            <HeaderTitle title={'Empty Leg Inquiry'} />
            <Helmet>
                <title>{'Daily offers - JustFlyEmpty'}</title>
            </Helmet>
            <div className={'daily-offers-container'}>
                <div className={'container confirmation-container'}>
                    <div className={'columns'} style={{ flex: 1, marginTop: '1.5rem' }}>
                        {status === 'success' ? renderSuccess() : renderError()}
                        <div className={'column'} style={{ paddingTop: 23 }}>
                            <TextTitle headingLevel={3}>{'Already a member?'}</TextTitle>
                            <p>
                                {
                                    'Ready to fly? Read about how to properly book a private charter flight, discover our insider tips on what to look out for and receive our daily offers for maximum savings.'
                                }
                            </p>
                            <br />
                            <a
                                rel={'canonical'}
                                href={`${LINK_URL}subscribe`}
                                className={'primary-btn medium-padding'}>
                                {'Subscribe'}
                            </a>
                        </div>
                    </div>

                    <MotoText
                        title={'ANY JET. ANY TIME. ANYWHERE.'}
                        titleTop={7}
                        marginTop={20}
                        marginBottom={20}
                    />
                </div>
            </div>
        </>
    )
}

export default EmptyLegInquiryConfirmationTemplate
