import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import HeaderTitle from '../../components/atoms/HeaderTitle/HeaderTitle'
import InputField from '../../components/atoms/InputField/InputField'
import MotoText from '../../components/atoms/MotoText/MotoText'
import TextTitle from '../../components/atoms/TextTitle/TextTitle'
import FormFooter from '../../components/molecules/FormFooter/FormFooter'

const BecomeAnAgentScene = () => {
    const [isScrolled, setIsScrolled] = useState(false)

    useEffect(() => {
        if (!isScrolled) {
            window.scrollTo({ top: 0 })
            setIsScrolled(true)
        }
    }, [isScrolled])

    const fields = [
        { label: 'Name', key: 'name', placeholder: 'Enter your name' },
        { label: 'Email', key: 'email', placeholder: 'john@doe.com' },
        { label: 'Phone number', key: 'phone', placeholder: '+1 809 123-4567' },
        { label: 'Your resume', key: 'file', placeholder: 'Choose Your cv', type: 'file' }
    ]
    const renderField = ({ label, key, placeholder, name, type }) => {
        return (
            <div className={'form-field-container'} key={key + label}>
                <InputField
                    id={key}
                    label={label}
                    placeholder={placeholder}
                    name={name}
                    inputType={type || 'text'}
                />
            </div>
        )
    }
    return (
        <>
            <Helmet>
                <title>{'Become our business partner - JustFlyEmpty'}</title>
            </Helmet>
            <HeaderTitle title={'Become our business partner'} />
            <div className={'container become-partner'}>
                <MotoText
                    title={'LET’S MAKE A BETTER FUTURE TOGETHER'}
                    titleTop={7}
                    marginTop={20}
                    marginBottom={20}
                />
                <TextTitle headingLevel={2}>
                    {'Ready to take you career to greater heights?'}
                </TextTitle>
                <div className={'columns'}>
                    <div className={'column is-half'}>
                        <p>
                            {
                                'Become a JustFlyEmpty Agent today and receive outstanding benefits such as:'
                            }
                        </p>
                        <ul className={'list'}>
                            <li>
                                <p>{'High affiliate commissions up to 70% '}</p>
                            </li>
                            <li>
                                <p>{'Continuous learning'}</p>
                            </li>
                            <li>
                                <p>{'Remote work from anywhere in the world'}</p>
                            </li>
                            <li>
                                <p>{'Dedicated team management to support you and more...'}</p>
                            </li>
                        </ul>
                        <p>{'Fill in your details and find out how to join our team.'}</p>
                        <img src={'/images/agent-signup.jpg'} alt={'Become our partner'} />
                    </div>
                    <div className={'column is-half'}>
                        <form>
                            {fields.map((field) => renderField(field))}
                            <FormFooter primaryActionTitle={'Submit'} />
                        </form>
                    </div>
                </div>
                <MotoText title={'JUST FLY. THAT’S SOLUTION.'} titleTop={7} marginBottom={20} />
            </div>
        </>
    )
}

export default BecomeAnAgentScene
