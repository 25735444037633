import { isEmailValid } from './flight'

export const isFieldValid = (key, value) => {
    switch (key) {
        case 'email':
            return isEmailValid(value)
        default:
            if (!value) {
                return false
            }
            return value !== ''
    }
}

export const validateForm = (data, requiredFields) => {
    const errors = []
    for (let i = 0; i < requiredFields.length; i += 1) {
        const key = requiredFields[i]
        if (!isFieldValid(key, data[key])) {
            errors.push(key)
        }
    }
    return errors
}
