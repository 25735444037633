import React from 'react'

function CardAbout({ children, title, description, subtitle, image }) {
    return (
        <div className={'card_container padding_row column'}>
            <div className={'icon_card padding_row '}>
                {image ? <img src={image} alt={title} /> : children}
            </div>
            <div className={'title_container'}>
                <p className={'title_card'}>
                    <strong className={'strong_text'}>{title}</strong>
                    {subtitle}
                </p>
            </div>
            <div>
                <p className={'descrption padding_row'}>{description}</p>
            </div>
        </div>
    )
}

export default CardAbout
