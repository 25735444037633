import React from 'react'
import EmptyLegInquiryConfirmationTemplate from '../../components/templates/dailyOffers/EmptyLegInquiryConfirmationTemplate'
import { getQueryStringParams } from '../../utilities/url'

const EmptyLegInquiryConfirmationScene = () => {
    return (
        <EmptyLegInquiryConfirmationTemplate
            params={getQueryStringParams(window.location.search)}
        />
    )
}
export default EmptyLegInquiryConfirmationScene
