import React from 'react'
import TextTitle from '../TextTitle/TextTitle'

const HeaderTitle = ({ title }) => {
    const hasTitle = () => {
        return title?.length > 0
    }

    return (
        <>
            {hasTitle() && (
                <div className={'header-title'}>
                    <div className={'container'}>
                        <TextTitle title={title} headingLevel={1} fontCase={'upper'} />
                    </div>
                </div>
            )}
        </>
    )
}

export default HeaderTitle
