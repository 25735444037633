import React, { useState } from 'react'
import { BASE_URL } from '../../services/api/config'
import { validateForm } from '../../utilities/form'
import { toJson } from '../../utilities/string'
import SubscribeTemplate from './templates/SubscribeTemplate'

const SubscribeScene = () => {
    const [state, setState] = useState({
        status: 'pending',
        shouldValidate: false,
        errors: []
    })

    const onStoreSubscriber = async ({ name, phone, email }) => {
        try {
            const request = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': true
                },
                body: await toJson({ name, phone, email })
            }
            const response = await fetch(`${BASE_URL}subscriber/new`, request)
            if (response.status === 200) {
                // const responseData = await response.json()
                // const { id } = responseData
                // ss
                // console.log('log', responseData)
            }

            // console.log('log', request.body, request)
            setState({
                ...state,
                errors: [],
                shouldValidate: false,
                status: 'success'
            })
        } catch (error) {
            // console.log('log', error)
            setState({
                ...state,
                // ...data,
                errors: [],
                shouldValidate: false,
                status: 'error'
            })
        }
    }

    const onSendForm = async (event) => {
        event.preventDefault()
        // const { onLogin, history } = this.props
        const data = {}
        const formData = new FormData(event?.target)

        // eslint-disable-next-line no-restricted-syntax
        for (const entry of formData.entries()) {
            // eslint-disable-next-line prefer-destructuring
            data[entry[0]] = entry[1]
        }

        const errors = validateForm(data, ['email', 'name'])
        if (errors.length > 0) {
            setState({
                ...state,
                errors,
                shouldValidate: true,
                status: 'pending'
            })
            return
        }

        setState({
            ...state,
            errors,
            shouldValidate: false,
            status: 'sending'
        })
        await onStoreSubscriber(data)
    }

    const onCloseModal = () => {
        setState({
            ...state,
            status: 'complete'
        })
    }

    return (
        <SubscribeTemplate
            status={state.status}
            onSendForm={onSendForm}
            state={state}
            onCloseModal={onCloseModal}
        />
    )
}

export default SubscribeScene
