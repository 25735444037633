import React from 'react'

const TextTitle = ({
    title,
    children,
    headingLevel: level = 1,
    fontCase = 'title',
    className = ''
}) => {
    const titleText = () => {
        return title || children
    }

    switch (level) {
        case 1:
            return <h1 className={`heading ${fontCase}-case ${className}`}>{titleText()}</h1>
        case 2:
            return <h2 className={`heading ${fontCase}-case ${className}`}>{titleText()}</h2>
        case 3:
            return <h3 className={`heading ${fontCase}-case ${className}`}>{titleText()}</h3>
        case 4:
            return <h4 className={`heading ${fontCase}-case ${className}`}>{titleText()}</h4>
        case 5:
            return <h5 className={`heading ${fontCase}-case ${className}`}>{titleText()}</h5>
        case 6:
            return <h6 className={`heading ${fontCase}-case ${className}`}>{titleText()}</h6>
        default:
            return <h1 className={`heading ${fontCase}-case ${className}`}>{titleText()}</h1>
    }
}

export default TextTitle
