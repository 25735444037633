import { AutoComplete, DatePicker } from 'antd'
import moment from 'moment'
import React from 'react'

const InputField = ({
    inputType = 'text',
    id,
    className,
    label = null,
    name,
    placeholder,
    value,
    isIndented = false,
    isRequired = false,
    min = null,
    max = null,
    onChange,
    defaultValue,
    options = [],
    isInvalid = false,
    errorMessage
}) => {
    const renderComponent = () => {
        switch (inputType) {
            case 'select':
                return (
                    <div>
                        <AutoComplete
                            options={options}
                            key={id}
                            value={value}
                            defaultValue={value}
                            selected={value}
                            placeholder={placeholder || ''}
                            // eslint-disable-next-line no-shadow
                            onChange={(value) => onChange(id, value)}
                            className={`${className} ${isInvalid ? 'error-field' : ''}`}
                        />
                    </div>
                )
            case 'checkbox':
                return (
                    <input
                        type={inputType}
                        id={id}
                        name={name || id}
                        className={`input-field ${isIndented ? 'indented-left' : ''} ${
                            className || ''
                        }`}
                        placeholder={placeholder}
                        value={value}
                        defaultValue={defaultValue}
                        required={isRequired}
                        min={min}
                        max={max}
                        onChange={(event) => onChange(id, event.target.value)}
                    />
                )

            case 'textarea':
                return (
                    <textarea
                        className={className}
                        // eslint-disable-next-line no-restricted-globals
                        onChange={() => onChange(id, event.target.value)}
                        value={defaultValue}
                        name={name}
                    />
                )

            case 'date':
                return (
                    <DatePicker
                        showToday
                        disabledDate={(current) => current && current < moment().endOf('day')}
                        onChange={(date) => {
                            onChange(id, date)
                        }}
                    />
                )

            default:
                return (
                    <div>
                        {errorMessage && isInvalid && (
                            <p className={'error-field-message'}>{errorMessage}</p>
                        )}
                        <input
                            type={inputType}
                            id={id}
                            name={name || id}
                            className={`input-field ${isIndented ? 'indented-left' : ''} ${
                                className || ''
                            } ${isInvalid ? 'error-field' : ''}`}
                            placeholder={placeholder}
                            value={value}
                            defaultValue={defaultValue}
                            required={isRequired}
                            min={min}
                            max={max}
                            onChange={(event) => onChange(id, event.target.value)}
                        />
                    </div>
                )
        }
    }

    return (
        <div className={`${inputType}-input-container ${className}`}>
            {label && (
                <label htmlFor={id} className={`${inputType}-label`}>
                    {label}
                </label>
            )}
            {renderComponent()}
        </div>
    )
}

export default InputField
