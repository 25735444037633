import React from 'react'

export const statusTypes = {
    FLIGHT: 'FLIGHT',
    SENDING: 'SENDING',
    SENT_SUCCESSFULLY: 'SENT_SUCCESSFULLY',
    INFO_SENT: 'INFO_SENT',
    SENDING_ERROR: 'SENDING_ERROR',
    SENDING_INFO_ERROR: 'SENDING_INFO_ERROR',
    DATA_INCOMPLETE: 'DATA_INCOMPLETE'
}

export const renderError = (formData) => {
    const { status = '' } = formData
    switch (status) {
        case statusTypes.SENDING_INFO_ERROR:
            return (
                <div className={'full-column flight-type-container'}>
                    <p className={'error'}>{`Sending was unsuccessful.\nPlease try again.`}</p>
                </div>
            )

        case statusTypes.DATA_INCOMPLETE:
            return (
                <div className={'full-column flight-type-container'}>
                    <p
                        className={
                            'error error-info'
                        }>{`Please complete all fields and try again.`}</p>
                </div>
            )

        default:
            return <div />
    }
}
