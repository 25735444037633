import React, { useState } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Footer from '../components/molecules/footer/Footer'
import TopMenu from '../components/molecules/header/TopMenu'
import MaintenanceTemplate from '../components/templates/MaintenanceTemplate'
import NotFoundTemplate from '../components/templates/NotFoundTemplate'
import AboutUsScene from '../scenes/aboutUs/AboutUsScene'
import BecomeAnAgentScene from '../scenes/become-an-agent/BecomeAnAgentScene'
import CharterInquiryScene from '../scenes/charter/CharterInquiryScene'
import DailyOffersHelpScene from '../scenes/dailyOffers/DailyOffersHelpScene'
import DailyOffersScene from '../scenes/dailyOffers/DailyOffersScene'
import EmptyLegInquiryConfirmation from '../scenes/emptyLegInquiry/EmptyLegInquiryConfirmationScene'
import HomeScene from '../scenes/home/HomeScene'
import PrivacyPolicyScene from '../scenes/PrivacyPolicyScene'
import StandardsScene from '../scenes/standards/StandardsScene'
import SubscribeScene from '../scenes/subscribe/SubscribeScene'
import TermsAndConditionsScene from '../scenes/TermsAndConditionsScene'
import MembershipScene from '../scenes/membership/MembershipScene'

const AppRouter = () => {
    const [scroll, setScroll] = useState(0)

    window.addEventListener('scroll', () => {
        setScroll(window.scrollY)
    })
    return (
        <div>
            <Router>
                <TopMenu scroll={scroll} />
                <Switch>
                    <Route path={'/signup/agent'} exact component={BecomeAnAgentScene} />
                    <Route path={'/notfound'} component={NotFoundTemplate} />
                    <Route path={'/maintenance'} component={MaintenanceTemplate} />
                    <Route path={'/'} exact component={HomeScene} />
                    <Route path={'/about'} component={AboutUsScene} />
                    <Route path={'/privacy-policy'} component={PrivacyPolicyScene} />
                    <Route path={'/terms'} component={TermsAndConditionsScene} />
                    <Route path={'/daily-offers'} component={DailyOffersScene} />
                    <Route path={'/how-it-works'} component={DailyOffersHelpScene} />
                    <Route path={'/charter-inquiry'} component={CharterInquiryScene} />
                    <Route path={'/subscribe'} component={SubscribeScene} />
                    <Route path={'/our-standards'} exact component={StandardsScene} />
                    <Route path={'/membership'} exact component={MembershipScene} />
                    <Route
                        path={'/empty-leg-confirmation'}
                        component={EmptyLegInquiryConfirmation}
                    />
                </Switch>
                <Footer />
            </Router>
        </div>
    )
}

export default AppRouter
