import moment from 'moment'
import React, { useState } from 'react'
import { BASE_URL, LINK_URL } from '../../../services/api/config'
import { filterAirports } from '../../../utilities/airports'
import { arrayContains } from '../../../utilities/array'
import { isFlightValid } from '../../../utilities/flight'
import { toJson } from '../../../utilities/string'
import InputField from '../../atoms/InputField/InputField'
import TextTitle from '../../atoms/TextTitle/TextTitle'
import CheckPicker from '../../molecules/CheckPicker/CheckPicker'
import FormFooter from '../../molecules/FormFooter/FormFooter'
import { statusTypes, renderError } from './selectors'

const CharterContactForm = ({ from, to, jets: jetsList }) => {
    const [formData, setFormData] = useState({
        pax: 1,
        from,
        to,
        jets: jetsList,
        airportOptions: [],
        charterType: 'one-way',
        date: '',
        errors: [],
        filteredAirports: {},
        status: statusTypes.FLIGHT
    })

    const onUpdateForm = async (key, value) => {
        const data = { ...formData }
        if (arrayContains(['from', 'to'], key)) {
            data.filteredAirports = await filterAirports(key, formData)
        }
        data[key] = value
        if (data.errors.length > 0) {
            data.errors = []
        } else {
            data.errors = [...(data.errors || []).filter((error) => key !== error)]
        }

        setFormData(data)
    }

    const onSendRequest = async () => {
        setFormData({ ...formData, jets: formData.jets || [], status: statusTypes.SENDING })
        switch (formData?.status) {
            case statusTypes.SENDING:
            case statusTypes.FLIGHT: {
                const request = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': true
                    },
                    body: await toJson({
                        ...formData
                    })
                }
                try {
                    const response = await fetch(`${BASE_URL}charter-inquiry`, request)
                    if (response.status === 200) {
                        const responseData = await response.json()
                        const { id } = responseData
                        setFormData({
                            ...formData,
                            status: statusTypes.SENT_SUCCESSFULLY,
                            inquiryID: id
                        })
                        return ''
                    }
                } catch (error) {
                    setFormData({ ...formData, status: statusTypes.SENDING_ERROR })
                }

                return ''
            }
            case statusTypes.SENT_SUCCESSFULLY:
                {
                    const request = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: await toJson({
                            id: formData?.inquiryID,
                            haveFlown: formData?.haveFlown || '',
                            numberOfFlights: formData?.numberOfFlights || '',
                            favoriteDestination: formData?.favoriteDestination || ''
                        })
                    }
                    const response = await fetch(`${BASE_URL}update-charter-inquiry`, request)
                    if (response.status === 200) {
                        setFormData({ ...formData, status: statusTypes.INFO_SENT })
                        return ''
                    }
                    setFormData({ ...formData, status: statusTypes.SENDING_INFO_ERROR })
                }
                break
            default:
                break
        }
        return ''
    }

    const onSubmitForm = async () => {
        if (formData?.status === statusTypes.SENT_SUCCESSFULLY) {
            if (
                formData?.haveFlown?.trim().length < 1 &&
                formData?.favoriteDestination?.trim().length < 1 &&
                formData?.numberOfFlights?.trim().length < 1
            ) {
                // eslint-disable-next-line no-alert
                alert('Please fill at least one value or cancel.')
                return
            }
            await onSendRequest()
            return
        }

        const validationKeys = ['from', 'to', 'date', 'name', 'email', 'phone']
        if (formData?.charterType !== 'one-way') {
            validationKeys.push('returnDate')
        }
        const { isValid, errors } = isFlightValid(formData, validationKeys)
        if (errors.length > 0) {
            setFormData({
                ...formData,
                errors,
                status: statusTypes.DATA_INCOMPLETE
            })
            return
        }

        if (isValid && errors.length < 1) {
            await onSendRequest()
        }
    }

    const onCancelForm = () => {
        window.location.href = '/#justflyprivate-section'
    }

    switch (formData?.status) {
        case statusTypes.INFO_SENT:
            return (
                <>
                    <h2>{'Thank you for sharing!'}</h2>
                    <p>
                        {
                            'You can expect a quote shortly.'
                            // 'We are going to prepare even better and more personalized experience for you.'
                        }
                    </p>
                    <FormFooter
                        primaryActionTitle={'Back to home'}
                        onClick={() => {
                            window.location.href = LINK_URL
                        }}
                    />
                </>
            )
        case statusTypes.SENT_SUCCESSFULLY:
        case statusTypes.SENDING_INFO_ERROR:
            return (
                <>
                    <h2 className={'success-info'}>{'Your request sent successfully!'}</h2>
                    <p>{'One of our agents will get back to you shortly.'}</p>
                    <div className={'full-column flight-type-container border-top padding-top'}>
                        <p>
                            {
                                'In order to make your experience ultimately personal, let us know you a little better:'
                            }
                        </p>
                    </div>
                    {renderError(formData)}
                    <div className={'full-column'}>
                        <InputField
                            id={'haveFlown'}
                            label={'Have you flown before?'}
                            // placeholder={formData?.haveFlown}
                            // isInvalid={formData?.errors.haveFlown}
                            defaultValue={formData?.haveFlown}
                            onChange={onUpdateForm}
                        />
                    </div>
                    <div className={'full-column'}>
                        <InputField
                            id={'numberOfFlights'}
                            label={'How many times a year do you fly?'}
                            defaultValue={formData?.numberOfFlights}
                            onChange={onUpdateForm}
                        />
                    </div>
                    <div className={'full-column'}>
                        <InputField
                            id={'favoriteDestination'}
                            label={'Do you have any favorite / frequent destination or route?'}
                            defaultValue={formData?.favoriteDestination}
                            value={formData?.favoriteDestination || ''}
                            onChange={onUpdateForm}
                        />
                    </div>
                    <FormFooter
                        primaryActionTitle={'Submit'}
                        section={formData?.formType}
                        cancelActionTitle={'Skip'}
                        onClick={onSubmitForm}
                        onCancel={() => {
                            window.location.href = LINK_URL
                        }}
                        status={formData?.status}
                    />
                </>
            )
        case statusTypes.SENDING:
        case statusTypes.SENDING_ERROR:
        default:
            return (
                <>
                    <div className={'full-column flight-type-container'}>
                        <TextTitle headingLevel={3}>{'selected route'}</TextTitle>
                    </div>
                    <div className={'full-column chosen-route'}>
                        <p>
                            <strong>{'From'}</strong>
                            {from}
                        </p>
                        <p>
                            <strong>{'To'}</strong> {to}
                        </p>
                    </div>
                    {renderError(formData)}
                    {formData?.status === statusTypes.SENDING_ERROR && (
                        <div className={'full-column flight-type-container'}>
                            <p className={'error error-info'}>
                                {`Sending was unsuccessful.\nPlease try again.`}
                            </p>
                        </div>
                    )}
                    <div className={'full-column flight-type-container'}>
                        <div className={'form-two-third-column'}>
                            <div className={'form-section-title'}>{'Direction'}</div>
                            <CheckPicker onChange={onUpdateForm} selected={formData?.charterType} />
                        </div>
                        <div className={'form-one-third-column'}>
                            <div className={'form-section-title'}>{'PAX'}</div>
                            <InputField
                                id={'pax'}
                                placeholder={formData?.pax}
                                inputType={'number'}
                                isInvalid={arrayContains(formData?.errors, 'pax')}
                                defaultValue={formData?.pax || 1}
                                min={1}
                                onChange={onUpdateForm}
                                className={'small-field'}
                            />
                        </div>
                    </div>

                    <div>
                        <div className={'full-column'}>
                            <div className={'form-section-title'}>
                                {formData?.charterType === 'round-trip'
                                    ? 'Departure and return'
                                    : 'Departure'}
                            </div>
                            <div className={'dates-group'}>
                                <InputField
                                    id={'date'}
                                    isInvalid={arrayContains(formData?.errors, 'date')}
                                    placeholder={'YYYY/MM/DD'}
                                    // value={new Date('tomorrow')}
                                    inputType={'date'}
                                    onChange={onUpdateForm}
                                    min={moment().add(1, 'days').format('YYYY/MM/DD')}
                                    className={'small-field'}
                                />
                                {formData?.charterType === 'round-trip' && (
                                    <InputField
                                        id={'returnDate'}
                                        placeholder={'YYYY/MM/DD'}
                                        isInvalid={arrayContains(formData?.errors, 'returnDate')}
                                        inputType={'date'}
                                        onChange={onUpdateForm}
                                        min={'tomorrow'}
                                        className={'small-field'}
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                    <div className={'form-section-title'}>{'Contact details'}</div>
                    <div className={'form-full-column'}>
                        <InputField
                            id={'name'}
                            placeholder={'Full name'}
                            // label={'Your name'}
                            isInvalid={arrayContains(formData?.errors, 'name')}
                            defaultValue={formData?.name || ''}
                            onChange={onUpdateForm}
                        />
                        <InputField
                            id={'email'}
                            isInvalid={
                                formData?.errors?.filter((error) => error === 'email').length > 0
                            }
                            placeholder={'Email address'}
                            defaultValue={formData?.email || ''}
                            onChange={onUpdateForm}
                        />
                    </div>
                    <div className={'form-full-column'}>
                        <InputField
                            id={'phone'}
                            placeholder={'Phone number'}
                            inputType={'phone'}
                            isInvalid={arrayContains(formData?.errors, 'phone')}
                            defaultValue={formData?.name || ''}
                            onChange={onUpdateForm}
                            type={'phone'}
                        />
                    </div>
                    <div className={'form-full-column'}>
                        <InputField
                            id={'note'}
                            label={'Please tell us if you have any special requests'}
                            inputType={'textarea'}
                            defaultValue={formData?.note || ''}
                            onChange={onUpdateForm}
                        />
                    </div>
                    <FormFooter
                        primaryActionTitle={
                            formData?.formType === 'flight' ? 'Book now' : 'Send request'
                        }
                        section={formData?.formType}
                        cancelActionTitle={'Cancel'}
                        onClick={onSubmitForm}
                        onCancel={onCancelForm}
                        status={formData?.status}
                    />
                </>
            )
    }
}
export default CharterContactForm

// const charterForm = document.getElementById('reserve-charter')

// if (charterForm) {
//     ReactDOM.render(
//         <CharterForm from={params.from} to={params.to} jetsList={params.jets} />,
//         charterForm
//     )
// }
