import { toJson } from '../../utilities/string'
import { BASE_URL } from './config'

export const loadDailyOffers = async () => {
    const response = await fetch(`${BASE_URL}empty-legs`)
    const { data, ...responseData } = await response.json()
    return { data, responseData } || {}
}

export const onSendEmptyLegInquiry = async (formData) => {
    const request = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': true
        },
        body: await toJson({
            ...formData
        })
    }
    const response = await fetch(`${BASE_URL}empty-legs/inquiry-empty-leg`, request)
    return response.json()
}
