import React from 'react'
// import { Link } from 'react-router-dom';

const MaintenanceTemplate = () => {
    return (
        <>
            <div>
                <h1>{'Maintanance'}</h1>
            </div>
        </>
    )
}
export default MaintenanceTemplate
