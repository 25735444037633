import React from 'react'
import InputField from '../../atoms/InputField/InputField'
import { arrayContains } from '../../../utilities/array'
import FormFooter from '../FormFooter/FormFooter'
import { BROCHURE_URL } from '../../../services/api/config'

const MembershipBrochureForm = ({ formData, onUpdateForm, onSubmitForm, error, url }) => {
    return (
        <>
            {error && <p className={'error'}>{error}</p>}
            {!url && (
                <>
                    <div className={'form-full-column'}>
                        <InputField
                            id={'name'}
                            placeholder={'John Doe'}
                            label={'Your name'}
                            isInvalid={arrayContains(formData?.errors, 'name')}
                            defaultValue={formData?.name || ''}
                            onChange={onUpdateForm}
                        />
                    </div>
                    <InputField
                        id={'email'}
                        label={'Email'}
                        isInvalid={arrayContains(formData?.errors, 'email')}
                        placeholder={'Email address'}
                        defaultValue={formData?.email || ''}
                        onChange={onUpdateForm}
                    />
                    <FormFooter primaryActionTitle={'Submit'} onClick={onSubmitForm} />
                </>
            )}
            {url && (
                <>
                    <FormFooter
                        primaryActionTitle={'Download Brochure'}
                        onClick={() => {
                            window.open(BROCHURE_URL)
                        }}
                        status={formData?.status}
                    />
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <button
                        className={'cancel'}
                        onClick={() => {
                            window.location.href = '/'
                        }}>
                        {'Cancel'}
                    </button>
                </>
            )}
        </>
    )
}

export default MembershipBrochureForm
